// ** == JS MAIN FILE == ** //

// Import Webflow
import { webflow } from './modules/_webflow.js';
webflow();

// Import modules
import { popup } from './modules/_popup.js';
popup();

// Import templates
import { carriere } from './templates/_carriere.js';
carriere();

import { scrollToHash } from './templates/_scrollToHash.js';
scrollToHash();