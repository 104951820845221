export const scrollToHash = () => {
    if (window.location.hash) {
        var hash = window.location.hash;

        if ($(hash).length) {
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            });
        }
    }
}