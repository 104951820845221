// Code to detect whenever the user is about to exit the page

// GSAP register
import gsap from "gsap";

export const popup = () => {

    const cookieName = "ctaDenied";

    // Function to set a cookie
    const setCookie = (name, duration) => {
        let expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + duration);
        const cookieValue = `${name}=1; expires=${expirationDate.toUTCString()}; path=/; secure`;
        document.cookie = cookieValue;
    };

    // Update Nov23: "Si quelqu'un rempli le form il faudrait un cookie différent de 365 jours pour ne plus revoir le popup"
    // Set a 365 days cookie if user clicks on the submit cta of any contact forms
    let forms = document.querySelectorAll('.contact1_form');
    if (forms.length > 0) {
        for (let currentForm of forms) {
            currentForm.querySelector('input[type="submit"]').addEventListener('click', () => {
                setCookie(cookieName, 365);
            });
        }
    }

    // Update Nov23: "Ils veulent finalement que le popup soit sur la page d'accueil seulement."
    if (!document.querySelector('.homepage')) {
        return;
    }

    // Function to check if a cookie exists
    const checkCookie = (name) => {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.indexOf(name + "=") === 0) {
                return true;
            }
        }
        return false;
    }

    // Check if the cookie exists
    if (checkCookie(cookieName)) {
        return;
    }

    let popup = document.querySelector(".modal-wrapper");
    if (popup) {
        // Set initial deformation for an effect on reveal
        gsap.set(popup, {
            autoAlpha: 0,
            scale: 0.95,
            skewX: 42,
        });

        let hidePopup = (e) => {
            e.preventDefault();

            // Unfreeze the body
            document.body.style.overflow = "visible";
            document.body.style.overflowX = "hidden !important";

            // Hide and delete the popup
            popup.style.display = "none";
            popup.remove();

            // Remove former event listener
            popup.removeEventListener("mouseout", showPopup);

            // Add a cookie to prevent the popup from appearing for 14 days
            setCookie(cookieName, 14);
        };

        let showPopup = (e) => {
            // Detect if the mouse is above the window
            if (e.clientY < 0 || e.clientX < 0) {
                // Show the popup
                popup.removeAttribute("data-hide");
                gsap.to(popup, {
                    autoAlpha: 1,
                    duration: 0.3,
                    scale: 1,
                    skewX: 0,
                });

                // Freeze the body
                document.body.style.overflow = "hidden";

                // Listen for clicks on the 'hide' cta
                popup.querySelector(".modal_deny-btn").addEventListener("click", hidePopup);

                // We can also hide the popup with the 'ESC' key
                document.addEventListener("keydown", (e) => {
                    if (e.key === "Escape") {
                        hidePopup(e);
                    }
                });
            }
        };

        window.addEventListener("mouseout", showPopup);
    }

    return;
};
