export const carriere = () => {
    //// Change input field style and content
    // A) Apply new style on input file
    // B) Transform the <div> in <label> and apply its new style
    // C) Inject text + input
    // D) Remove useless inner label
    // E) Prevent to add a new file if one is already inserted
    // F) AddEventListener on field to display filename
    // G) Remove file after a click 
    // H) Hightlight missing required file in red if none are present

    let fileInputs = document.querySelectorAll('.contact1_form [type="file"]');
    if (fileInputs.length > 0) {
       for (let currentFile of fileInputs) {

            // Add the default label text for input fields
            const addDefaultText = target => {
                let defaultLabel = '<span data-js="default-label">' + target.getAttribute('data-text') + '</span>';
                target.querySelector('[data-js="default-label"]').innerHTML = defaultLabel;
            };

            let divToRemove = currentFile.parentNode;
            // A) Apply new style on input file
            currentFile.classList.add('file-form-input');
            // B) Transform the <div> in <label> and apply its new style
            let newLabel = document.createElement('label');
            newLabel.setAttribute('for', currentFile.id);
            newLabel.setAttribute('data-text', currentFile.parentNode.querySelector('label').textContent); // Keep original text
            divToRemove.replaceWith(newLabel);
            newLabel.classList.add('file-form-label', 'wf-group');
            // C) Inject text + input
            newLabel.innerHTML = '<i class="add-icon">+</i><span data-js="default-label">' + newLabel.getAttribute('data-text') + '</span>' + divToRemove.innerHTML;
            // D) Remove the now useless inner label
            newLabel.querySelector('label').remove();
            
            // E) Prevent to add a new file if one is already inserted
            newLabel.querySelector('[type="file"]').addEventListener('click', e => {
                if (e.currentTarget.value != "") {
                    e.preventDefault();
                    e.stopPropagation();
                }
            });

            // F) AddEventListener on field to display filename
            newLabel.querySelector('[type="file"]').addEventListener('change', e =>{
                e.currentTarget.parentNode.classList.remove('missing');
                let currentField = e.currentTarget;
                let removeIcon = newLabel.querySelector('i');
                removeIcon.classList.add('remove-icon');
                const file = e.target.files;
                newLabel.querySelector('[data-js="default-label"]').innerHTML = file[0].name;

                // G) Remove file after a click 
                removeIcon.addEventListener('click', e => {
                    e.currentTarget.classList.remove('remove-icon');
                    currentField.value = "";
                    addDefaultText(newLabel);
                    e.preventDefault();
                });
            });
        }

        // H) Hightlight missing required file in red if none are present
        let submitBtn = document.querySelector('[type="submit"]');
        submitBtn.addEventListener('click', e => {
            for (let currentFile of document.querySelectorAll('.contact1_form [type="file"]')) {
                let currentParent = document.querySelector('[for="' + currentFile.id + '"]');
                if (currentFile.hasAttribute('required') && currentFile.files.length === 0) {
                    currentParent.classList.add('missing');
                    e.preventDefault();
                    e.stopPropagation();
                }
            }
        });
    }
}
